import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import { FRANCHISE_PORTAL_SESSION_KEY } from '../../../tools/constants';
import getUserCountry from '../../../tools/getUserCountry';
import $window from '../../../tools/window';
import { LDFLAGS, FRANCHISE_DATA } from '../queries';

/**
 * Gets the data needed to determine if the user should see the franchise Portal
 * based on either their shipTo country or their defaulted store country
 * @param {String} defaultCountry Country code of default site country
 */
const useFranchiseCountryData = (defaultCountry) => {
  const [initialValue, setInitialValue] = useState(false);

  const { data: ldFlagData } = useQuery(LDFLAGS);
  const franchisePortalEligible = ldFlagData?.config?.franchisePortal?.value ?? false;

  useEffect(() => {
    const value = sessionStorage.getItem(FRANCHISE_PORTAL_SESSION_KEY);
    setInitialValue(value);
  }, []);

  const [viewedFranchisePortal, setViewedFranchisePortal] = useSessionStorage(
    FRANCHISE_PORTAL_SESSION_KEY,
    initialValue,
  );
  useEffect(() => {
    const handleFranchiseCountryReset = () => {
      setViewedFranchisePortal(false);
    };

    $window.addEventListener('mfe:resetFranchiseCountry', handleFranchiseCountryReset);

    return () => {
      $window.removeEventListener('mfe:resetFranchiseCountry', handleFranchiseCountryReset);
    };
  }, [setViewedFranchisePortal]);
  const franchiseEligible = franchisePortalEligible && !viewedFranchisePortal;
  const { data, loading, error } = useQuery(FRANCHISE_DATA, {
    skip: !franchiseEligible,
  });
  if (!franchiseEligible || loading || error) {
    if (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching franchise data', error);
    }
    return {
      countryCode: '',
      countryName: '',
      redirectUrl: '',
      showFranchisePortal: false,
      isMultiCountry: false,
      franchiseAllowedCountries: [],
      setViewedFranchisePortal,
    };
  }

  const franchiseData = data?.franchiseCountries || {};
  const { countryFromIP, countries } = franchiseData;
  const activeCountry = countries?.find((country) => country.countryCode === countryFromIP);

  /*
    franchiseAllowedCountries is the array of shipTo countries for which the franchise
    portal is allowed (in this case, with respect to the country the user's IP is in.)
  */
  const franchiseAllowedCountries = activeCountry?.franchiseAllowedCountries ?? [];
  const userCountry = getUserCountry(franchiseData, defaultCountry);

  /*
    So, finally, we determine if the user should see the franchise portal based on
    (1) if franchise portal is enabled overall for their IP-located country and
    (2) if their "userCountry" (which is their shipTo if specified) is in the list of
    allowed countries that the portal is enabled for in the user's IP-located country.
  */
  const showFranchisePortal = activeCountry?.hasFranchisePortal
    && franchiseAllowedCountries
      .some((allowedCountry) => allowedCountry === userCountry.countryCode);

  return {
    countryCode: activeCountry?.countryCode ?? '',
    countryName: activeCountry?.name ?? '',
    redirectUrl: activeCountry?.redirectUrl ?? '',
    showFranchisePortal,
    isMultiCountry: franchiseAllowedCountries.length > 1,
    setViewedFranchisePortal,
  };
};

export default useFranchiseCountryData;
