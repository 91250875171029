import PropTypes from 'prop-types';
import React from 'react';
import { SMALLEST_POSSIBLE_BLANK_IMAGE } from '../../tools/constants';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  image: PropTypes.string,
  isChecked: PropTypes.bool,
  /** Makes label into screen-reader text. To be replaced with aria-label on <input>.
   */
  isLabelVisible: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  showPlaceholder: PropTypes.bool,
  state: PropTypes.string,
  handleSwatchHover: PropTypes.func,
  swatch: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    product: PropTypes.shape({
      id: PropTypes.string,
      imageSet: PropTypes.shape({
        primaryFaceOutImage: PropTypes.string,
      }),
    }),
  }),
};

function SwatchTile({
  id,
  image = undefined,
  isChecked = false,
  isLabelVisible = true,
  label = undefined,
  name,
  onChange = () => { },
  onClick = undefined,
  showPlaceholder = true,
  state = undefined,
  type,
  value,
  swatch,
  handleSwatchHover,
}) {
  const preLoadedImage = showPlaceholder ? SMALLEST_POSSIBLE_BLANK_IMAGE : image;
  return (
    <div
      className="swtg-input-inner-wrapper"
      data-state={state}
      onBlur={() => handleSwatchHover(null)}
      onFocus={() => handleSwatchHover(swatch)}
      onMouseOut={() => handleSwatchHover(null)}
      onMouseOver={() => handleSwatchHover(swatch)}
    >
      <input
        checked={isChecked}
        className="swtg-input"
        id={id}
        name={name}
        onChange={onChange}
        onClick={onClick}
        type={type}
        value={value}
      />
      <div className="swtg-input-tile-wrapper">
        <div className="swtg-image-outer-wrapper">
          <div className="swtg-image-inner-wrapper" style={isChecked ? { border: '2px solid #374e62' } : {}}>
            <img alt="" src={preLoadedImage} width="19" />
          </div>
        </div>
        <label
          className={label && isLabelVisible ? 'swtg-label' : 'screen-reader-text'}
          data-label-visible={label && isLabelVisible ? 'true' : 'false'}
          htmlFor={id}
        >
          {label}
        </label>
      </div>
    </div>
  );
}

SwatchTile.propTypes = propTypes;

export default SwatchTile;
