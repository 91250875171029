import {
  ApolloClient, ApolloLink, ApolloProvider, HttpLink,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { getCacheId, getFrontendId } from '@xp-utilities/web';
import { sha256 } from 'crypto-hash';
import { stripIgnoredCharacters, print } from 'graphql';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import parseEndpointParameters from '../tools/parseEndpointParameters';

const simpleCacheMerge = {
  merge: (existing = {}, incoming = {}) => ({ ...existing, ...incoming }),
};

export default function hydrate({ frontend, component }) {
  const Component = component;
  const cacheId = getCacheId(PACKAGE.name, frontend);
  const el = document.getElementById(getFrontendId(PACKAGE.name, frontend));

  // To support a single file js type delivery, need to handle component endpoints
  // existing in js, but not in dom
  if (!el) return;

  const config = window[`APOLLO_STATE__${cacheId}`];

  // Goal uri example: 'http://localhost:7002?catalogId=10901storeId=10051&langId=-1&brand=anf&store=a-us',

  const persistedQueriesLink = createPersistedQueryLink({
    useGETForHashedQueries: true,
    sha256,
    generateHash(query) {
      return Promise.resolve(sha256(stripIgnoredCharacters(print(query))));
    },
  });

  const uri = `/api/bff/catalog?catalogId=${config.catalogId}&storeId=${config.storeId}&langId=${config.langId}&brand=${config.brand}&store=${config.store}&currency=${config.currency}&country=${config.country}&urlRoot=${config.urlRoot}&segment=${config.segment}&storePreview=${config.storePreview}&aemContentAuthoring=${config.aemContentAuthoring}`;

  const httpLink = new HttpLink({
    uri,
    credentials: 'same-origin',
    print(ast, originalPrint) {
      return stripIgnoredCharacters(originalPrint(ast));
    },
  });

  const batchHttpLink = new BatchHttpLink({
    uri,
    credentials: 'same-origin',
    batchMax: 50,
    batchInterval: 10,
  });

  // construct apollo client with cached results computed on server
  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          // eslint-disable-next-line no-console
          graphQLErrors.forEach(({ message, locations, path }) => console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ));
        }
        // eslint-disable-next-line no-console
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
    ]).split(
      (operation) => operation?.query?.definitions?.[0]?.operation === 'mutation' || operation.getContext()?.batch,
      batchHttpLink,
      persistedQueriesLink.concat(httpLink),
    ),
    cache: new InMemoryCache(
      {
        typePolicies: {
          Facet: { keyFields: false },
          FacetGroup: { keyFields: false },
          SearchResults: { keyFields: false },
          FacetValue: { keyFields: false },
          Query: {
            fields: {
              textFor: simpleCacheMerge,
              config: simpleCacheMerge,
              category: simpleCacheMerge,
              storeAttribute: simpleCacheMerge,
            },
          },
        },
      },
    ).restore(config.CACHE),
  });

  hydrateRoot(
    el,
    <ApolloProvider client={client}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...parseEndpointParameters(config)} />
    </ApolloProvider>,
  );
}
