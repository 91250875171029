import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import BreadcrumbBar from '../BreadcrumbBar/BreadcrumbBar';
import CategoryRailNavigation from '../CategoryRailNavigation/CategoryRailNavigation';
import SingleAemEspot from '../SingleAemEspot';
import CategoryPage from './CategoryPage';
import CustomerRecommendationsMFE from './CustomerRecommendationsMFE';
import style from './UnifiedCategoryPage.module.scss';
import UNIFIED_CATEGORY_PAGE_QUERY from './queries';

function UnifiedCategoryPage({
  brand,
  categoryId,
  facet,
  filter,
  grouped,
  rows,
  seqSlot,
  sort,
  start,
  store,
  storeId,
}) {
  const { data } = useQuery(UNIFIED_CATEGORY_PAGE_QUERY, {
    variables: {
      categoryId,
    },
  });
  const { value: filtersLabel } = useTranslatedText('filters', { fallback: 'Filters' });

  return (
    <div className="scope-1892">
      <CustomerRecommendationsMFE />
      {!!data?.category?.espotIdentifier && (
        <SingleAemEspot espotId={`${data.category.espotIdentifier}-breadcrumb-top`} />
      )}
      <div className={style.breadcrumbs}>
        <BreadcrumbBar brand={brand} categoryId={categoryId} />
      </div>
      {!!data?.category?.espotIdentifier && (
        <SingleAemEspot espotId={`${data.category.espotIdentifier}-breadcrumb-bottom`} />
      )}
      <div className={style.row}>
        <div className={style.row_left}>
          <div className={style.nav}>
            <CategoryRailNavigation categoryId={categoryId} />
          </div>
          <section aria-label={filtersLabel} className={style.filters}>
            <div className="sort-and-filter-facet-container" />
          </section>
        </div>
        <main className={style.row_right}>
          {/* TODO: Optimization: Render Shop My Store filter and main filters directly rather than
          via portals initiated from CategoryPage component */}
          <CategoryPage
            brand={brand}
            categoryId={categoryId}
            facet={facet}
            filter={filter}
            grouped={grouped}
            rows={rows}
            seqSlot={seqSlot}
            sort={sort}
            start={start}
            store={store}
            storeId={storeId}
          />
        </main>
      </div>
    </div>
  );
}

UnifiedCategoryPage.propTypes = {
  brand: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  facet: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  filter: PropTypes.string,
  grouped: PropTypes.bool,
  rows: PropTypes.string,
  sort: PropTypes.string,
  start: PropTypes.string,
  storeId: PropTypes.string,
  store: PropTypes.string,
  seqSlot: PropTypes.string,
};

export default UnifiedCategoryPage;
