import { gql, useQuery } from '@apollo/client';
import { Disclosure } from 'anf-core-react';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useTranslatedText from '../../hooks/useTranslatedText';
import addUrlParameters from '../../tools/addUrlParameters';
import { CATEGORY_RAIL_URL_ANALYTICS_PARAMETERS } from '../../tools/constants';
import { LOADING_MESSAGE } from '../Messages/Messages';

const getData = gql`
  query Data(
    $categoryId: String!,
    $displayTypes: [String!]!,
  ) {
    category(
      categoryId: $categoryId,
    ) {
      localNavigation {
        displayTypes(displayTypes: $displayTypes) {
          bottomEspot {
            espotId
            htmlContent
          }
          categories {
            categoryId
            displayTypes(displayTypes: $displayTypes) {
              categories {
                categoryId
                isCurrent
                listItemQaAttribute
                name
                url
              }
              displayType
            }
            isCurrent
            listItemQaAttribute
            name
            url
          }
          displayType
          heading {
            key
            value
          }
        }
        headingLabel
        headingQaAttribute
        headingScreenReaderOnly
        headingUrl
      }
    }
  }
`;

const CategoryRailNavigation = ({
  categoryId,
}) => {
  const { data, loading, error } = useQuery(getData, {
    variables: {
      categoryId,
      displayTypes: ['regular', 'featured', 'clearance'],
    },
  });

  const navCategoriesLabel = useTranslatedText('navCategoriesLabel', { fallback: 'Categories' });

  if (loading) {
    return LOADING_MESSAGE;
  }

  // Per team discussion, show nothing instead of error content/text in this case
  if (error || !data?.category?.localNavigation?.displayTypes) {
    return null;
  }

  // Define displayable display type group categories based on field nullability and UI requirements
  const displayableDisplayTypeCategories = (categories) => categories
    .reduce((displayableCategories, category) => {
      /**
       * Category must have:
       *   - categoryId to serve as a React key
       *   - name and url for valid, accessible link
       */
      if (
        !category
        || !category.categoryId
        || !category.name
        || !category.url
      ) {
        return displayableCategories;
      }

      // Create copy of category with displayable display type groups, if any
      const categoryWithDisplayableDisplayTypes = {
        ...category,
        displayTypes: (category.displayTypes ?? [])
          .reduce((displayableGroups, displayTypeGroup) => {
            /**
             * Group must have:
             *   - displayType to serve as a React key
             *   - categories to render
             */
            if (
              !displayTypeGroup
              || !displayTypeGroup.displayType
              || !displayTypeGroup.categories
            ) {
              return displayableGroups;
            }

            // Create copy of group with displayable categories
            const groupWithDisplayableCategories = {
              ...displayTypeGroup,
              categories: displayableDisplayTypeCategories(displayTypeGroup.categories),
            };

            /**
             * Group must have at least 1 displayable category to render
             */
            if (groupWithDisplayableCategories.categories.length) {
              displayableGroups.push(groupWithDisplayableCategories);
            }

            return displayableGroups;
          }, []),
      };

      displayableCategories.push(categoryWithDisplayableDisplayTypes);
      return displayableCategories;
    }, []);
  // Define displayable display type groups based on field nullability and UI requirements
  const displayableDisplayTypeGroups = cloneDeep(data.category.localNavigation.displayTypes).reduce(
    (displayableGroups, displayTypeGroup) => {
      /**
       * Group must have:
       *   - displayType to serve as a React key
       *   - bottomEspot or categories to render
       */
      if (
        !displayTypeGroup
        || !displayTypeGroup.displayType
        || (!displayTypeGroup.bottomEspot && !displayTypeGroup.categories)) {
        return displayableGroups;
      }

      // If no categories, group must have bottomEspot to render
      if (!displayTypeGroup.categories && displayTypeGroup.bottomEspot) {
        displayableGroups.push(displayTypeGroup);
        return displayableGroups;
      }

      // Create copy of group with displayable categories
      const groupWithDisplayableCategories = {
        ...displayTypeGroup,
        categories: displayableDisplayTypeCategories(displayTypeGroup.categories),
      };

      /**
       * Group must have at least 1 displayable category to render, or else
       * bottomEspot to render
       */
      if (
        groupWithDisplayableCategories.categories.length
        || groupWithDisplayableCategories.bottomEspot
      ) {
        displayableGroups.push(groupWithDisplayableCategories);
      }

      return displayableGroups;
    },
    [],
  );

  const renderNestedDisplayTypeGroups = (nestedDisplayTypeGroups) => nestedDisplayTypeGroups.map(
    (displayTypeGroup) => (
      <ul key={displayTypeGroup.displayType} className="mfe-rail-navigation__sub-list">
        {displayTypeGroup.categories.map((category) => (
          <li
            key={category.categoryId}
            className="mfe-rail-navigation__sub-list-item"
            data-aui={category.listItemQaAttribute}
          >
            <a
              aria-current={category.isCurrent ? 'page' : null}
              className={classNames(
                'mfe-rail-navigation__sub-list-item-link',
                { 'mfe-rail-navigation__sub-list-item-link--current': category.isCurrent },
              )}
              href={addUrlParameters(category.url, CATEGORY_RAIL_URL_ANALYTICS_PARAMETERS)}
            >
              {category.name}
            </a>
          </li>
        ))}
      </ul>
    ),
  );

  return (
    <nav
      aria-label={navCategoriesLabel.value}
      className="mfe-rail-navigation mfe-category-rail-navigation"
    >
      {
      data.category.localNavigation.headingLabel
      && (
        <h1
          className="mfe-rail-navigation__heading h2"
          data-aui={data.category.localNavigation.headingQaAttribute}
        >
          {
            // eslint-disable-next-line no-nested-ternary -- This nesting seems fine
            data.category.localNavigation.headingUrl
            && !data.category.localNavigation.headingScreenReaderOnly
              ? (
                <a
                  className="mfe-rail-navigation__heading-link"
                  href={data.category.localNavigation.headingUrl}
                >
                  {data.category.localNavigation.headingLabel}
                </a>
              )
              : (
                data.category.localNavigation.headingScreenReaderOnly
                  ? <div className="screen-reader-text">{data.category.localNavigation.headingLabel}</div>
                  : data.category.localNavigation.headingLabel
              )
          }
        </h1>
      )
    }
      <div className="store-toggle-container-target" />
      {displayableDisplayTypeGroups.map((displayTypeGroup, i) => {
        const categoryListItems = displayTypeGroup.categories?.map((category, j) => {
          const listItemLink = (
            <a
              aria-current={category.isCurrent ? 'page' : null}
              className={classNames(
                'mfe-rail-navigation__list-item-link',
                { 'mfe-rail-navigation__list-item-link--current': category.isCurrent },
              )}
              href={addUrlParameters(category.url, CATEGORY_RAIL_URL_ANALYTICS_PARAMETERS)}
            >
              {category.name}
            </a>
          );

          return (
            <li
              key={category.categoryId}
              className="mfe-rail-navigation__list-item"
              data-aui={category.listItemQaAttribute}
            >
              {
              category.displayTypes.length > 0
                ? (
                  <div className="mfe-rail-navigation__list-item-disclosure">
                    <ListItemDisclosure
                      id={`category-rail-navigation-disclosure-${i}-${j}`}
                      isDefaultExpanded={category.isCurrent}
                      label={listItemLink}
                    >
                      {renderNestedDisplayTypeGroups(
                        category.displayTypes,
                      )}
                    </ListItemDisclosure>
                  </div>
                )
                : listItemLink
            }
            </li>
          );
        });

        return (
          <React.Fragment key={displayTypeGroup.displayType}>
            {displayTypeGroup.heading && displayTypeGroup.heading.value && (
            <h2 className="mfe-rail-navigation__list-heading">
              {displayTypeGroup.heading.value}
            </h2>
            )}
            <ul className="mfe-rail-navigation__list">
              {categoryListItems}
              {displayTypeGroup.bottomEspot && (
              <li>
                <div
                  className={
                    displayTypeGroup.bottomEspot.htmlContent
                      ? 'genericESpot js-aem-content aem-content aem-enabled'
                      : 'genericESpot genericESpot--empty'
                  }
                  // eSpots require this
                  dangerouslySetInnerHTML={{
                    __html: displayTypeGroup.bottomEspot.htmlContent,
                  }}
                  data-aem-currency=""
                  data-espotname={displayTypeGroup.bottomEspot.espotId}
                />
              </li>
              )}
            </ul>
          </React.Fragment>
        );
      })}
    </nav>
  );
};

CategoryRailNavigation.propTypes = {
  categoryId: PropTypes.string.isRequired,
};

function ListItemDisclosure({
  children,
  id,
  isDefaultExpanded = false,
  label,
}) {
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);
  const clickHandler = () => setIsExpanded(!isExpanded);
  return (
    <Disclosure
      id={id}
      isExpanded={isExpanded}
      label={label}
      onClick={clickHandler}
    >
      {children}
    </Disclosure>
  );
}

ListItemDisclosure.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  isDefaultExpanded: PropTypes.bool,
  label: PropTypes.node.isRequired,
};

export default CategoryRailNavigation;
