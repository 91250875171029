import { gql } from '@apollo/client';

const UNIFIED_CATEGORY_PAGE_QUERY = gql`
  query UNIFIED_CATEGORY_PAGE_QUERY($categoryId: String!) {
    category(categoryId: $categoryId) {
      espotIdentifier
    }
  }
`;

export default UNIFIED_CATEGORY_PAGE_QUERY;
