import PropTypes from 'prop-types';
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useDebounce, useLocalStorage } from 'react-use';
import useFocus from '../../hooks/useFocus';
import useTranslatedText from '../../hooks/useTranslatedText';
import {
  AUTOSUGGEST_THRESHOLD,
  CLICKED_SUGGESTION_KEY,
  PRESERVED_DEPARTMENT_ID,
  SEARCH_FLYOUT_DEBOUNCE,
} from '../../tools/constants';
import prepareSearchTerm from '../../tools/prepareSearchTerm';
import saveRecentSearch from '../../tools/recentSearches';
import $window from '../../tools/window';
import AutoSuggestResult from '../AutoSuggestResult/AutoSuggestResult';
import BreakpointProvider from '../BreakpointProvider';
import SearchDefaultView from '../SearchDefaultView';
import SearchInputField from '../SearchInputField/SearchInputField';
import style from './SearchBox.module.scss';

// Custom Hooks:
import usePersistedSearchTerm from './hooks/usePersistedSearchTerm';
import {
  usePopularSearches,
  useSuggestions,
  useDepartmentPreservation,
  useGenderLessSearch,
} from './hooks/useQueries';

const SearchBox = (({
  betaFlagGroup = '',
  searchTermChange = () => { },
  searchUrl = '',
}) => {
  //  Initialize State
  const [isProcessing, setIsProcessing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [userTypedSearchTerm, setUserTypedSearchTerm] = useState('');
  const [listPosition, setListPosition] = useState(-1);
  const [departmentId, setDepartmentId] = useState('');
  const [departmentGender, setDepartmentGender] = useState('');
  const [, setCompletionType] = useLocalStorage(CLICKED_SUGGESTION_KEY, []);
  const [preservedDeptId, setPreservedDeptId] = useLocalStorage(PRESERVED_DEPARTMENT_ID, '');
  const currentForm = useRef();
  const searchInputFieldRef = useRef(null);
  const [isFocused, setFocus, setFocusReturn] = useFocus(searchInputFieldRef);

  // Fetch data and process side effects:
  const clearLabel = useTranslatedText('autoSuggestClearButtonLabel', { fallback: 'Clear' });
  const placeholder = useTranslatedText('autoSuggestDesktopPlaceholder', { fallback: 'Search' });

  const popularSearchesList = usePopularSearches();
  const [getSuggestions, suggestions] = useSuggestions();
  const userDeptPreferenceFlag = useDepartmentPreservation();
  const genderLessSearch = useGenderLessSearch();

  const {
    combinedSuggestions,
    products,
    productTypes,
  } = suggestions;
  usePersistedSearchTerm(setSearchTerm, getSuggestions, setListPosition);
  const suggestionsVisible = searchTerm.trim().length >= AUTOSUGGEST_THRESHOLD;
  const displayedData = useMemo(() => (suggestionsVisible
    ? combinedSuggestions : []), [suggestionsVisible, combinedSuggestions]);

  useEffect(() => {
    if (userDeptPreferenceFlag) {
      // Need to re-visit this logic if it works on switching regions
      if (preservedDeptId) {
        setDepartmentId(preservedDeptId);
      }
    }
    return () => { };
  }, [userDeptPreferenceFlag, preservedDeptId]);

  // Event Handlers:

  const clearButtonHandler = () => {
    setSearchTerm('');
    setUserTypedSearchTerm('');
    setFocusReturn(true);
    searchTermChange('');
  };

  const [, cancelSearchQuery] = useDebounce(() => {
    if (searchTerm && searchTerm.length >= AUTOSUGGEST_THRESHOLD
      && !isProcessing && listPosition === -1) {
      getSuggestions({ variables: { searchTerm } });
      if ($window.digitalData) {
        $window.digitalData.trigger('mfe-search-type-ahead', {
          searchTerm,
        });
      }
    }
  }, SEARCH_FLYOUT_DEBOUNCE, [searchTerm]);

  const searchInputHandler = useCallback((e) => {
    setSearchTerm(e.currentTarget.value || '');
    setUserTypedSearchTerm(e.currentTarget.value || '');
    if (e.target.value.length >= AUTOSUGGEST_THRESHOLD) {
      setListPosition(-1);
    }
    searchTermChange(e.currentTarget.value);
  }, [searchTermChange]);

  // Only fired when submission is triggered from <input>
  const formSubmitHandler = async () => {
    await setSearchTerm(prepareSearchTerm(searchTerm));
    setCompletionType(null);
    saveRecentSearch(searchTerm);
    setIsProcessing(true);
  };

  /**
   * Handles the submission of a list item from the auto-suggest list.
   * @param {Object} listItemData - object containing
    * @property {string} listItemData.departmentIdentifier - Department Identifier of Suggestion
    * @property {number} listItemData.index - Index of the list item
    * @property {string} listItemData.type - Type of suggestion, either 'suggestions' or 'products'
    * @property {string} listItemData.name - Selected Search term from keyboardHandler
   * @property {string} listItemData.value - Selected Search term from mouseHandler
   * @param {string} completionType - The type of completion that was clicked.
   * @returns {void}
   * */
  const listItemSubmitHandler = useCallback(async (listItemData, completionType = null) => {
    const {
      suggestedTerm,
      departmentIdentifier = '',
      departmentName = '',
      value,
    } = listItemData;
    const suggestedTermValue = suggestedTerm || value;

    setCompletionType(completionType);
    saveRecentSearch(suggestedTermValue);
    setIsProcessing(true);
    setDepartmentId(departmentIdentifier);
    setDepartmentGender(departmentName);
    setPreservedDeptId(departmentIdentifier);

    await setSearchTerm(suggestedTermValue);
    currentForm.current.submit();
  }, [setCompletionType, setPreservedDeptId]);

  const keyDownHandler = useCallback((e) => {
    const { key } = e;
    const lastIndex = displayedData?.length - 1;

    const keyboardActions = {
      ArrowDown: () => {
        if (listPosition < lastIndex) {
          setListPosition((previousPosition) => previousPosition + 1);
          setSearchTerm(displayedData[listPosition + 1]?.name ?? '');
          setDepartmentId(displayedData[listPosition + 1]?.departmentIdentifier ?? '');
          setDepartmentGender(displayedData[listPosition + 1]?.departmentName ?? '');
        } else {
          setListPosition(-1);
          setSearchTerm(userTypedSearchTerm);
        }
      },
      ArrowUp: () => {
        e.preventDefault();
        const newPosition = listPosition === -1 ? lastIndex : listPosition - 1;
        setListPosition(newPosition);
        if (newPosition >= 0) {
          setSearchTerm(displayedData[newPosition]?.name ?? '');
          setDepartmentId(displayedData[newPosition]?.departmentIdentifier ?? '');
          setDepartmentGender(displayedData[newPosition]?.departmentName ?? '');
        }
        if (newPosition === -1) {
          setSearchTerm(userTypedSearchTerm);
        }
      },
      Escape: () => {
        cancelSearchQuery();
        setSearchTerm('');
        setUserTypedSearchTerm('');
        setListPosition(-1);
      },
      Enter: () => {
        if (listPosition >= 0) {
          listItemSubmitHandler(displayedData[listPosition], displayedData[listPosition]?.imageId ? 'products' : 'suggestions');
        }
      },
    };
    keyboardActions[key]?.();
  }, [displayedData, listPosition, userTypedSearchTerm, cancelSearchQuery, listItemSubmitHandler]);

  const blurHandler = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setFocus(false);
      $window.dispatchEvent(new CustomEvent('mfe:autoSuggestFlyout:Hide'));
    }
  };

  const focusHandler = (e) => {
    if (!e.target.classList.contains('clear-button') && !e.target.classList.contains('search-button')) {
      setFocus(true);
      $window.dispatchEvent(new CustomEvent('mfe:autoSuggestFlyout:Show'));
    }
  };

  const showAutoSuggestResult = () => (
    <BreakpointProvider>
      <AutoSuggestResult
        isFormFocused={isFocused}
        isProcessing={isProcessing}
        listItemSubmitHandler={listItemSubmitHandler}
        listPosition={listPosition}
        productData={products}
        productTypesData={productTypes}
        searchTerm={searchTerm}
      />
    </BreakpointProvider>
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      ref={currentForm}
      action={searchUrl}
      className={`${style.mfeSearchForm} rs-nav__search rs-nav__search-wrapper scope-1892`}
      data-testid="search-input-wrapper"
      id="product-search-form"
      method="get"
      onBlur={blurHandler}
      onFocus={focusHandler}
      onKeyDown={keyDownHandler}
      onSubmit={formSubmitHandler}
      role="search"
    >
      {betaFlagGroup
        ? <input id="search-beta-flag" name="group" type="hidden" value={betaFlagGroup} /> : null}
      {departmentId ? (
        <input
          id="flyout-deptartment-id"
          name="departmentId"
          type="hidden"
          value={departmentId}
        />
      ) : null}
      {userDeptPreferenceFlag ? (
        <input
          id="user-dept-preference"
          name="departmentId"
          type="hidden"
          value={preservedDeptId}
        />
      ) : null}
      {(genderLessSearch && departmentGender) ? (
        <input id="genderFacet" name="facet" type="hidden" value={`gender:(${departmentGender})`} />
      ) : null}
      <SearchInputField
        ref={searchInputFieldRef}
        autoComplete="off"
        buttonLabelText={placeholder.value}
        clearButtonLabelText={clearLabel.value}
        clearButtonOnClick={clearButtonHandler}
        id="search-input-field"
        isClearButton={suggestionsVisible}
        labelText={placeholder.value}
        name="searchTerm"
        onChange={searchInputHandler}
        placeholder={placeholder.value}
        value={searchTerm}
      />
      {suggestionsVisible
        ? (showAutoSuggestResult())
        : (
          <SearchDefaultView
            isFormFocused={isFocused}
            listItemSubmitHandler={listItemSubmitHandler}
            popularSearchesList={popularSearchesList}
            searchLabel={placeholder.value}
            wrapperId="auto-suggest-desktop-list"
          />
        )}
    </form>
  );
});

SearchBox.propTypes = {
  betaFlagGroup: PropTypes.string,
  searchTermChange: PropTypes.func,
  searchUrl: PropTypes.string,
};

export default SearchBox;
