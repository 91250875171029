import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import useFocusAfterSearch from '../../hooks/useFocusAfterSearch';
import addUrlParameters from '../../tools/addUrlParameters';
import { IMAGE_SIZE_POLICY, PRODUCT_CARD_URL_ANALYTICS_PARAMETERS, SMALLEST_POSSIBLE_BLANK_IMAGE } from '../../tools/constants';
import getImageUrl from '../../tools/getImageUrl';
import tryImageLoad from '../../tools/tryImageLoad';
import { imageSetType, swatchType } from '../types';
import style from './productCard.module.scss';

function ProductCardImages({
  forceLifestyleImage = false,
  hasModelImagery = false,
  hasProdImagery = false,
  hoveredSwatch = null,
  imageSet: {
    primaryFaceOutImage,
    primaryHoverImage,
    prodImage,
    modelImage,
    lifestyleImage,
  },
  imageHostName = '',
  isFirstProduct = false,
  noImageFoundImgUrl = '',
  onClick = () => {},
  productName = '',
  productPageUrl = null,
  selectedSwatchName = null,
  showPlaceholder = false,
}) {
  const productCardLink = useRef(null);
  const primaryImageRef = useRef();
  const secondaryImageRef = useRef();
  const hasHoverImage = primaryHoverImage !== primaryFaceOutImage;

  useFocusAfterSearch(productCardLink, !isFirstProduct);

  // if we have no hope of displaying these images let's not even try
  if (!noImageFoundImgUrl || !imageHostName) return null;

  const computedPrimaryFaceOutImage = () => {
    if (hasModelImagery) {
      return modelImage || primaryFaceOutImage;
    }
    if (hasProdImagery) {
      return prodImage || primaryFaceOutImage;
    }
    if (forceLifestyleImage) {
      return lifestyleImage || primaryFaceOutImage;
    }
    return primaryFaceOutImage;
  };
  // Try prodImage, otherwise use the No Image Found image
  // Choosing not to tryImageLoad elsewhere because
  // most of the time images should load successfully
  const handleOnError = (imageRef) => {
    const currentImageRef = imageRef.current;
    if (!currentImageRef) return;
    currentImageRef.onerror = null; // prevents looping
    const prodImageUrl = getImageUrl(imageHostName, prodImage, IMAGE_SIZE_POLICY.MEDIUM);
    tryImageLoad(prodImageUrl)
      .then(() => { currentImageRef.src = prodImageUrl; })
      .catch(() => {
        tryImageLoad(noImageFoundImgUrl)
          .then(() => { currentImageRef.src = noImageFoundImgUrl; })
          // eslint-disable-next-line no-console
          .catch(() => console.log('No image could be loaded'));
      });
  };

  const computeImageUrl = (isPrimary) => {
    let imageId;
    if (hoveredSwatch) {
      imageId = hoveredSwatch.product.imageSet.primaryFaceOutImage;
    } else {
      imageId = (!isPrimary && primaryHoverImage)
        ? primaryHoverImage : computedPrimaryFaceOutImage();
    }
    return showPlaceholder
      ? SMALLEST_POSSIBLE_BLANK_IMAGE
      : getImageUrl(imageHostName, imageId, IMAGE_SIZE_POLICY.MEDIUM);
  };

  const computeAltText = (isPrimary) => {
    let altText = productName;
    if (hoveredSwatch) {
      altText = `${productName}, ${hoveredSwatch.name}`;
    } else if (selectedSwatchName) {
      altText = `${productName}, ${selectedSwatchName}`;
    }
    return `${altText} view ${isPrimary ? '1' : '2'}`;
  };

  const computeDataAuiText = (isPrimary) => {
    let dataAuiText = '';
    if (isPrimary) {
      dataAuiText = 'product-card-image';
    } else {
      dataAuiText = hoveredSwatch ? 'product-card-hovered-swatch-image' : 'product-card-hover-image';
    }
    return dataAuiText;
  };

  return (
    <div className={`${hasHoverImage ? style.hasHoverImage : ''}`}>
      <a
        ref={productCardLink}
        href={addUrlParameters(productPageUrl, PRODUCT_CARD_URL_ANALYTICS_PARAMETERS)}
        onClick={onClick}
      >
        <img
          ref={primaryImageRef}
          alt={computeAltText(true)}
          className={style.productCardImage_1}
          data-aui={computeDataAuiText(true)}
          onError={() => handleOnError(primaryImageRef)}
          src={computeImageUrl(true)}
          width="400"
        />
        {hasHoverImage && (
          <img
            ref={secondaryImageRef}
            alt={computeAltText(false)}
            className={style.productCardImage_2}
            data-aui={computeDataAuiText(false)}
            onError={() => handleOnError(secondaryImageRef)}
            src={computeImageUrl(false)}
            width="400"
          />
        )}
      </a>
    </div>
  );
}

ProductCardImages.propTypes = {
  forceLifestyleImage: PropTypes.bool,
  imageSet: imageSetType.isRequired,
  hoveredSwatch: swatchType,
  imageHostName: PropTypes.string,
  isFirstProduct: PropTypes.bool,
  noImageFoundImgUrl: PropTypes.string,
  hasModelImagery: PropTypes.bool,
  hasProdImagery: PropTypes.bool,
  onClick: PropTypes.func,
  productName: PropTypes.string,
  productPageUrl: PropTypes.string,
  selectedSwatchName: PropTypes.string,
  showPlaceholder: PropTypes.bool,
};

export default ProductCardImages;
