/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import BreakpointProvider from '../BreakpointProvider';
import DigitalDataProvider, {
  DD_DISABLE_SWATCH_HOVER,
  DD_HYPERLINK_DESC,
  DD_MODEL_IMAGERY_TEST,
  DD_PAGINATION_TEST,
} from '../DigitalDataProvider';
import ProductGridContextProvider from '../ProductGridContextProvider';
import StoreDetailsProvider from '../StoreDetailsProvider/StoreDetailsProvider';
import MiniGrid from './MiniGrid';

const MiniGridWrapper = (props) => (
  <ProductGridContextProvider>
    <StoreDetailsProvider store={props.store} storeId={props.storeId}>
      <BreakpointProvider>
        <DigitalDataProvider
          keys={[
            DD_DISABLE_SWATCH_HOVER,
            DD_HYPERLINK_DESC,
            DD_MODEL_IMAGERY_TEST,
            DD_PAGINATION_TEST,
          ]}
        >
          <MiniGrid {...props} />
        </DigitalDataProvider>
      </BreakpointProvider>
    </StoreDetailsProvider>
  </ProductGridContextProvider>
);

MiniGridWrapper.propTypes = {
  storeId: PropTypes.string,
  store: PropTypes.string,
};

export default MiniGridWrapper;
