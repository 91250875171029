import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useUpdateMySaves from '../../hooks/useUpdateMySaves';
import miniGridStyle from '../MiniGrid/MiniGrid.module.scss';
import { productType, mySavesItemType } from '../types';
import productCardClickBehaviorType, {
  CLICK_BEHAVIORS,
} from '../types/productCardClickBehaviorType';
import ProductCardButtons from './ProductCardButtons';
import ProductCardImages from './ProductCardImages';
import ProductDetailsSection from './ProductDetailsSection';
import Swatches from './ProductSwatches';
import SocialProofBadge from './SocialProofBadge';
import style from './productCard.module.scss';
import {
  triggerMiniGridAnalytics,
} from './tools/triggerProductAnalytics';
import useImagePreloading from './tools/useImagePreloading';
import useQuickView from './tools/useQuickView';
import useSwatchHover from './tools/useSwatchHover';

/**
 * ProductCard component renders product cards with images, details, swatches, and action buttons.
 *
 * @param {Object} prop - the props object
 * @param {Object} props.algonomyOnClickAnalytics - Analytics data for Algonomy click events.
 * @param {string} props.brand - the brand
 * @param {string} props.categoryId - The category ID
 * @param {string} props.className - Additional class names for the product card
 * @param {string} props.clickBehavior - The click behavior for the product card
 * @param {boolean} props.deferImageLoad - Whether to defer image loading
 * @param {boolean} props.hasSwatchHover - Whether the swatches have hover functionality
 * @param {boolean} props.hasHyperlinkShortDescriptor - Show/Hide shortdescriptors as hyperlinks
 * @param {boolean} props.hadSwatchInteraction - Whether there was a swatch interaction
 * @param {string} props.imageHostName - The hostname for the product images
 * @param {boolean} props.isDesktop - Whether the view is in desktop or mobile
 * @param {boolean} props.isFirstProduct - Whether this is the first product in the list
 * @param {boolean} props.isMiniGrid - Whether the product card is in a mini grid layout
 * @param {boolean} props.isMiniGridSpotlightCard - Checking for mini grid spotlight card
 * @param {boolean} props.hasModelImagery - Whether the product has model image
 * @param {boolean} props.hasProdImagery - Whether the product has prod image
 * @param {Object} props.mySavesItems - The saved items for the user
 * @param {string} props.noImageFoundImgUrl - The URL for the "no image found" placeholder
 * @param {Function} props.onSwatchInteraction - Callback for swatch interaction
 * @param {Object} props.product - The product data
 * @param {Function} props.refetch - Function to refetch the product data
 */
function ProductCard({
  algonomyOnClickAnalytics = {},
  brand = '',
  categoryId = '',
  className = '',
  clickBehavior = CLICK_BEHAVIORS.productPage,
  deferImageLoad = true,
  hasHyperlinkShortDescriptor = false,
  hasModelImagery = false,
  hasProdImagery = false,
  hasSwatchHover = false,
  hadSwatchInteraction = false,
  imageHostName = '',
  isDesktop = false,
  isFirstProduct = false,
  isMiniGrid = false,
  isMiniGridSpotlightCard = false,
  mySavesItems = {},
  noImageFoundImgUrl = '',
  onSwatchInteraction = () => {},
  product: {
    badges,
    collection,
    defaultSwatchSequence,
    departmentName,
    id: productId,
    imageSet,
    kic,
    memberPrice,
    name,
    price,
    productPageUrl,
    promoMessaging,
    shortDescriptors,
    socialProofMessage,
    swatchList,
  },
  refetch = () => {},
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isQuickviewOpen, setIsQuickviewOpen] = useState(false);
  const [hoveredSwatch, setHoveredSwatch] = useState(null);

  const faceoutImageType = imageSet.primaryFaceOutImage?.replace(/[A-Z\d\-_]/g, '');

  const [height, setHeight] = useState(0);

  const { productCardRef, setIsHoveredWithImagePreloading, imagesLoaded } = useImagePreloading(
    swatchList,
    imageHostName,
    imageSet,
    setIsHovered,
    setHeight,
  );

  const { handleSavesClick, isSaved } = useUpdateMySaves({
    productId,
    kic,
    collectionId: collection,
    swatchSequence: defaultSwatchSequence,
    longsku: '',
    colorCode: defaultSwatchSequence,
    price,
    brand,
    categoryId,
    name,
    isMiniGrid,
    mySavesItems,
    refetch,
  });

  const {
    triggerQuickView,
  } = useQuickView({
    algonomyOnClickAnalytics,
    brand,
    categoryId,
    faceoutImageType,
    isMiniGrid,
    product: {
      collection, defaultSwatchSequence, productId, imageSet, productName: name, socialProofMessage,
    },
    setIsQuickviewOpen,
  });

  const { handleSwatchHover } = useSwatchHover({
    hasSwatchHover,
    hadSwatchInteraction,
    hoveredSwatchId: hoveredSwatch?.id,
    onSwatchInteraction,
    setHoveredSwatch,
  });

  const selectedSwatchName = swatchList?.find((swatch) => swatch.product.id === productId)?.name ?? '';

  const handleCardHover = (value) => {
    // Disable hover functionality for mobile
    if (isDesktop) {
      setIsHoveredWithImagePreloading(value);
    }
  };

  const handleCardFocus = () => {
    handleCardHover(true);
    setIsQuickviewOpen(false);
  };

  const handleCardBlur = (e) => {
    /**
     * Consider the card still focused if it or any of its focusable
     * descendants are next to be focused. This is particularly important so
     * that all swatches remain existent/focusable as you tab through them
     */
    handleCardHover(productCardRef.current.contains(e.relatedTarget));
  };

  const handleProductCardClick = (event) => {
    if (clickBehavior === CLICK_BEHAVIORS.quickview) {
      event.preventDefault();
      triggerQuickView(event);
    } else if (clickBehavior === CLICK_BEHAVIORS.productPage) {
      if (isMiniGrid && categoryId) {
        triggerMiniGridAnalytics();
      }
    }
  };

  const showHyperlinkShortDescriptors = !!hasHyperlinkShortDescriptor;

  return (
    <li
      ref={productCardRef}
      className={classNames(style.productCard, className, {
        [style.hover]: isHovered,
        [miniGridStyle.spotlightCard]: isMiniGridSpotlightCard,
        [style.topBadge]: socialProofMessage,
      })}
      data-aui="product-card"
      data-intlkic={kic}
      onBlur={handleCardBlur}
      onFocus={handleCardFocus}
      onMouseEnter={() => handleCardHover(true)}
      onMouseLeave={() => handleCardHover(false)}
      style={{ maxHeight: isHovered ? height : null }}
    >
      <div className={`${style.template} product-template`}>
        <SocialProofBadge
          brand={brand}
          isDesktop={isDesktop}
          socialProofMessage={socialProofMessage}
        />
        <div className={style['product-image-section']}>
          <ProductCardImages
            forceLifestyleImage={isMiniGridSpotlightCard}
            hasModelImagery={hasModelImagery}
            hasProdImagery={hasProdImagery}
            hoveredSwatch={hoveredSwatch}
            imageHostName={imageHostName}
            imageSet={imageSet}
            isFirstProduct={isFirstProduct}
            noImageFoundImgUrl={noImageFoundImgUrl}
            onClick={handleProductCardClick}
            productName={name}
            productPageUrl={productPageUrl}
            selectedSwatchName={selectedSwatchName}
            showPlaceholder={deferImageLoad && !imagesLoaded}
          />
          <ProductCardButtons
            isSaved={isSaved}
            onQuickViewClick={triggerQuickView}
            onSaveClick={handleSavesClick}
            showQuickView={!isDesktop || isHovered || isQuickviewOpen}
          />
        </div>
        <div className={`${style.content} product-content`}>
          {swatchList?.length > 0 && (
            <Swatches
              handleSwatchHover={handleSwatchHover}
              hasSwatchHover={hasSwatchHover}
              imageHostName={imageHostName}
              isDesktop={isDesktop}
              limitSwatches={!isHovered}
              productId={productId}
              productName={name}
              productPageUrl={productPageUrl}
              showPlaceholder={deferImageLoad && !imagesLoaded}
              swatchList={swatchList}
            />
          )}
          <ProductDetailsSection
            badges={badges}
            brand={brand}
            departmentName={departmentName}
            handleProductCardClick={handleProductCardClick}
            memberPrice={memberPrice}
            price={price}
            productName={name}
            productPageUrl={productPageUrl}
            promoMessaging={promoMessaging}
            shortDescriptors={shortDescriptors}
            showHyperlinkShortDescriptors={showHyperlinkShortDescriptors}
          />
        </div>
      </div>
    </li>
  );
}

ProductCard.propTypes = {
  algonomyOnClickAnalytics: PropTypes.shape({
    placementObj: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      treatments: PropTypes.arrayOf(PropTypes.shape({
        control: PropTypes.bool,
        testId: PropTypes.number,
        testType: PropTypes.string,
        treatmentId: PropTypes.string,
        treatmentName: PropTypes.string,
      })).isRequired,
    }),
    productIndex: PropTypes.number,
  }),
  brand: PropTypes.string,
  categoryId: PropTypes.string,
  className: PropTypes.string,
  clickBehavior: productCardClickBehaviorType,
  deferImageLoad: PropTypes.bool,
  hasModelImagery: PropTypes.bool,
  hasProdImagery: PropTypes.bool,
  hasSwatchHover: PropTypes.bool,
  hasHyperlinkShortDescriptor: PropTypes.bool,
  hadSwatchInteraction: PropTypes.bool,
  imageHostName: PropTypes.string,
  isDesktop: PropTypes.bool,
  isFirstProduct: PropTypes.bool,
  isMiniGrid: PropTypes.bool,
  isMiniGridSpotlightCard: PropTypes.bool,
  mySavesItems: mySavesItemType,
  noImageFoundImgUrl: PropTypes.string,
  onSwatchInteraction: PropTypes.func,
  product: productType.isRequired,
  refetch: PropTypes.func,
};

export default ProductCard;
