import PropTypes from 'prop-types';
import React from 'react';
import QuickviewButton from './ProductCardQuickviewButton';
import SaveButton from './ProductCardSaveButton';
import style from './productCard.module.scss';

function ProductCardButtons({
  isSaved = false,
  onQuickViewClick = () => {},
  onSaveClick = () => {},
  showQuickView = true,
}) {
  return (
    <>
      <SaveButton onClick={onSaveClick} saved={isSaved} />
      {showQuickView && (
      <div className={style.buttons} data-aui="product-card-buttons">
        <QuickviewButton onClick={onQuickViewClick} />
      </div>
      )}
    </>
  );
}

ProductCardButtons.propTypes = {
  isSaved: PropTypes.bool,
  onQuickViewClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  showQuickView: PropTypes.bool,
};

export default ProductCardButtons;
